import { Controller } from "stimulus"
import $ from "jquery"
import moment from "moment"
import { fire } from "@rails/ujs"
//import "daterangepicker"
import "mz_ui/components/mz_daterange_picker"

let getPicker = c => $(c.element).data('daterangepicker');

export default class extends Controller {
  static targets = [ 'from', 'to' ]

  connect() {
    const inputFrom = $(this.fromTarget).find('input[type=text]')[0];
    const inputTo = $(this.toTarget).find('input[type=text]')[0];
    if ((inputFrom == null) || (inputTo == null)) { return; }

    const hiddenFrom = $(this.fromTarget).find('input[type=hidden]')[0];
    const hiddenTo = $(this.toTarget).find('input[type=hidden]')[0];

    const time = this.data.get('time');

    const displayFormat = time ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY';
    const submitFormat = time ? 'YYYY-MM-DDTHH:mm:ssZ' : 'YYYY-MM-DD';

    const bindTo = this.element;

    const defaultDateStart = hiddenFrom.value ? moment(hiddenFrom.value) : moment();
    const defaultDateEnd = hiddenTo.value ? moment(hiddenTo.value) : moment();

    $(bindTo).mzDaterangePicker({
      autoUpdateInput: false,
      singleDatePicker: false,
      linkedCalendars: false,
      showDropdowns: true,
      timePicker: !!time,
      timePicker24Hour: true,
      locale: {
        format: displayFormat,
        applyLabel: 'Применить',
        cancelLabel: 'Отмена',
      },
      startDate: defaultDateStart,
      endDate: defaultDateEnd,
      opens: "center"
    }, function(start, end, label) {
      hiddenFrom.value = moment(start).format(submitFormat);
      inputFrom.value = moment(start).format(displayFormat);
      fire(hiddenFrom, 'change');
      hiddenTo.value = moment(end).format(submitFormat);
      inputTo.value = moment(end).format(displayFormat);
      return fire(hiddenTo, 'change');
    });

    const picker = getPicker(this);

    $(bindTo)
      .off('click.daterangepicker')
      .off('keydown.daterangepicker');

    $(inputFrom).on('keyup', function() { return picker.elementChangedStart($(this).val()); });
    $(inputTo).on('keyup', function() { return picker.elementChangedEnd($(this).val()); });

    return $([inputFrom, inputTo])
      .on('focus', function(e) {
        picker.show();
        return e.stopPropagation();
    });
  }

  disconnect() {
    const picker = getPicker(this);
    if (picker) { return picker.remove(); }
  }
}
