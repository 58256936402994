import { Controller } from "stimulus"
import { activateLink, activateReload } from "mz_ui/utils/activate_link"

export default class extends Controller {
  bubbleLoad(event) {
    activateLink(this.element.getAttribute('href'), this.element);
    if (event != null) { return event.preventDefault(); }
  }

  bubbleReload(event) {
    activateReload(this.element);
    if (event != null) { return event.preventDefault(); }
  }
}
