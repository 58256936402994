import { Controller } from "stimulus"
import { matches } from "@rails/ujs"
import { activateLink } from "mz_ui/utils/activate_link"
import $$  from "mz_ui/utils/dom"

export default class extends Controller {
  visit(event) {
    if (this.clickStartTime) { this.setClickEnd(event); }
    // TODO: rethink 'a' support
    if (!matches(event.target, 'a') && this.isLeftClick(event) && this.isClickFast() && (this.canVisit !== true)) {
      // target = event initiator
      // currentTarget = element with data-action handler
      event.stopPropagation();
      const target = this.data.has('bubble') && this.data.get('bubble') ? event.target : $$('body');
      activateLink(this.getUrl(event), target);
    }
    return this.resetClickTimes();
  }

  stopVisit(event) {
    return this.canVisit = true;
  }

  startVisit(event) {
    return this.canVisit = false;
  }

  setClickStart(event) {
    if (event.button === 0) { return this.clickStartTime = new Date; }
  }

  setClickEnd(event) {
    if (event.button === 0) { return this.clickEndTime = new Date; }
  }

  resetClickTimes() {
    delete this.clickStartTime;
    return delete this.clickEndTime;
  }

  isClickFast() {
    if (!this.clickEndTime || !this.clickStartTime) { return true; }
    const fastTimeClick = 300;
    return (this.clickEndTime - this.clickStartTime) < fastTimeClick;
  }

  isLeftClick(event) {
    if (!(event instanceof MouseEvent)) { return true; }
    return event.button === 0;
  }

  getUrl(event) {
    let url;
    return url = event.target.getAttribute('data-link-url') ||
      event.target.getAttribute('data-link-href') ||
      event.currentTarget.getAttribute('data-link-url') ||
      event.currentTarget.getAttribute('data-link-href');
  }
}
