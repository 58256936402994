import { Controller } from "stimulus"
import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

Chart.plugins.unregister(ChartDataLabels)

export default class extends Controller {
  connect() {
    let ctx = this.element.getContext('2d')

    let labels = JSON.parse(this.data.get('labels')) || []
    let colors = JSON.parse(this.data.get('colors')) || []
    let data = JSON.parse(this.data.get('data')) || []
    let title = this.data.get('title')
    let datasetLabel = this.data.get('datasetLabel')
    let suggestedMax = this.data.get('suggestedMax')
    let suggestedMin = this.data.get('suggestedMin')

    this.chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          label: datasetLabel,
          data: data,
          backgroundColor: colors
        }]
      },
      options: {
        maintainAspectRatio: false,
        title: {
          display: true,
          text: title
        },
        scales: {
          yAxes: [{
            ticks: {
              suggestedMax: suggestedMax,
              suggestedMin: suggestedMin
            }
          }]
        }
      }
    })
  }

  disconnect() {
    this.chart.destroy()
  }
}
