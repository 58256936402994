import { Controller } from "stimulus"
import $ from "jquery"

export default class extends Controller {
  connect() {
    console.log(this.data.get('contractsType'))
    console.log(this.data.get('notSignReason'))
    this.toggleContractsType(this.data.has('contractsType') && this.data.get('contractsType') === 'unsigned')
    this.toggleNotSignReasonSelect((this.data.has('notSignReason') && this.data.get('notSignReason') === 'other') && (this.data.has('contractsType') && this.data.get('contractsType') === 'unsigned'))
  }

  doContractsTypeSelect(event) {
    const сontractsType = event.target.value
    this.toggleContractsType(
      сontractsType === 'unsigned'
    )
  }

  toggleContractsType(notSigned) {
    if (notSigned) {
      $('#not_sign_reason').show()
    } else {
      $('#not_sign_reason').hide()
    }
  }

  doNotSignReasonSelect(event) {
    const reason = event.target.value
    this.toggleNotSignReasonSelect(
      reason === 'other'
    )
  }

  toggleNotSignReasonSelect(isOther) {
    if (isOther) {
      $('#not_sign_reason_label').show()
    } else {
      $('#not_sign_reason_label').hide()
    }
  }

}
