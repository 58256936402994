// see https://mikerogers.io/2019/09/16/using-actioncable-and-stimulus-to-remotely-update-partials-in-rails-6

export default {
  renderPartial(target, html) {
    let newBody = this._parseHTMLResponse(html);

    // Replace all data-turbolinks-permanent elements in the body with what was there
    // previously. This is useful for elements the user might interact with, such
    // as forms or dropdowns.
    let permanentNodes = target.querySelectorAll("[id][data-turbolinks-permanent]");
    permanentNodes.forEach(function(element){
      var oldElement = newBody.querySelector(`#${element.id}[data-turbolinks-permanent]`)
      oldElement.parentNode.replaceChild(element, oldElement);
    });

    // Remove all the current nodes from our element.
    while( target.firstChild ) { target.removeChild( target.firstChild ); }

    while( newBody.firstChild ) { target.appendChild( newBody.firstChild ); }

    // When we're sending a new partial, which is a full replacement of our
    // element & not just a group of children.
    ////if( newBody.childElementCount === 1 && newBody.firstElementChild.dataset.realtimePartialKey === this.data.get("key") ){
    ////  while( newBody.firstElementChild.firstChild ) { target.appendChild( newBody.firstElementChild.firstChild ); }
    ////} else {
    // Append the new nodes.
    ////while( newBody.firstChild ) { target.appendChild( newBody.firstChild ); }
    ////}
  },

  // From: https://stackoverflow.com/a/42658543/445724
  // using .innerHTML= is risky. Instead we need to convert the HTML received
  // into elements, then append them.
  // It's wrapped in a <template> tag to avoid invalid (e.g. a block starting with <tr>)
  // being mutated inappropriately.
  _parseHTMLResponse(responseHTML){
    let parser = new DOMParser();
    let responseDocument = parser.parseFromString( `<template>${responseHTML}</template>` , 'text/html');
    let parsedHTML = responseDocument.head.firstElementChild.content;
    return parsedHTML;
  }
}
