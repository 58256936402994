import { Controller } from "stimulus"
import Chart from 'chart.js'

import ChartDataLabels from 'chartjs-plugin-datalabels'

Chart.plugins.unregister(ChartDataLabels)

export default class extends Controller {
  connect() {
    let ctx = this.element.getContext('2d')

    let labels = JSON.parse(this.data.get('labels')) || []
    let colors = JSON.parse(this.data.get('colors')) || []
    let data = JSON.parse(this.data.get('data')) || []
    let title = this.data.get('title')
    let type = this.data.get('type')
    let aspectRatio = this.data.get('ratio')
    let animationDuration = this.data.get('animationDuration')

    this.chart = new Chart(ctx, {
      plugins: [ChartDataLabels],
      type: type,
      data: {
        labels: labels,
        datasets: [{
          data: data,
          backgroundColor: colors
        }]
      },
      options: {
        aspectRatio,
        animation: {
          duration: animationDuration
        },
        legend: {
          labels: {
            fontColor: '#000'
          }
        },
        title: {
          fontColor: '#000',
          display: true,
          text: title
        },
        plugins: {
          datalabels: {
            backgroundColor: '#fff',
            color: colors,
            borderRadius: 4,
            font: {
              weight: 'bold'
            }
          }
        }
      }
    })
  }

  disconnect() {
    this.chart.destroy()
  }
}
